// Bootstrap components
.display-4 {
  line-height: 0.98;

  @include media-breakpoint-down(md) {
    font-size: 2.25rem;
  }
}

.row-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-around;
  }

  .image-container:hover {
    box-shadow: 0 0 20px rgba(33, 33, 33, .2);
  }

  .image-container {
    width: 200px;

    @include media-breakpoint-down(sm) {
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }

    .image {
      width: 206px;
      height: 168px;
      background-repeat: no-repeat;

      &.monitor {
        background-image: url('/images/nature_on_screen.svg');
      }

      &.learn {
        background-image: url('/images/book_love.svg');
      }

      &.improve {
        background-image: url('/images/superhero.svg');
      }

      &.transportation {
        background-image: url('/images/learn_transportation.svg');
      }

      &.household {
        background-image: url('/images/learn_household.svg');
      }

      &.shopping {
        background-image: url('/images/learn_shopping.svg');
      }

      &.food {
        background-image: url('/images/learn_food.svg');
      }

      &.clothing {
        background-image: url('/images/learn_clothing.svg')
      }
    }

    .title {
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    .text {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }
  }
}

// Custom Components
.home-row {
  margin-bottom: 5rem;
}

.hero-img-right {
  @include media-breakpoint-down(lg) {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 80px;
  }
}

.hero-img-left {
  @include media-breakpoint-down(lg) {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 80px;
  }
}

.navbar-collapse {
  text-align: center;
}

.contact-us label {
  text-align: right;
  padding-left: 0rem;

  @include media-breakpoint-down(xs) {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0rem;
  }
}

.contact-us input,
.contact-us textarea {
  @include media-breakpoint-down(xs) {
    text-align: left;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 0rem;
  }
}

.dropdown-menu {
  width: 100%;
}

#hovering:hover {
  background-color: rgba(104, 191, 142, 0.4);
}

.contact-form input,
.contact-form .row,
.contact-form .form-group {
  @include media-breakpoint-down(xs) {
    margin-bottom: 0rem;
  }
}

.log-in-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $full-height;
}

.log-in-logo {
  width: 150px;
}

.log-in-title {
  color: theme-color('primary');
}

.log-in-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  min-width: 265px;
}

.footer {
  background-color: #f2f2f2;
  width: 100%;
  color: theme-color('primary');

  p {
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.footer__logo {
  width: 150px;
}

.accordion {
  background-color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-bottom: 3rem;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

// End of Survey Page

.table th,
.table td {
  padding: 0.5rem;
}

.profile-container {
  margin: auto;
  max-width: 1400px;
  min-height: $full-height;
  justify-content: center;
}

.profile-card {
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-header {
  width:100%;
  background: rgba(209, 223, 215, 0.6);
}

.card-footer {
  width:100%;
  height: 100%;
  background: rgba(209, 223, 215, 0.6);
}
.center-logo-carousel {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.detail-card {
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: auto;

}

.detail-close {
  position: absolute;
  right: 5px;
}

.right-column {
  padding: 50px;
  display: block;
}

.detail-header {
  padding: 20px;
}

.box {
  float: left;
  align-items: start;
  display: block;
  width: 50%;
}

.category-name {
  width: 50%;
}

.h3-align {
  text-align: center;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

.divider-image {
  width: 15px;
  height: auto;
  display: inline-block;
}

.profile-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
  overflow: hidden;
}

.button-image {
  height: 35px;
  width: 35px;
  object-fit: cover;
  overflow: hidden;
}

.profile-card-text {
  border-top: 1px solid theme-color('primary');
  width: 100%;
  padding: 1.25rem 1.25rem 0rem 1.25rem;
}

.profile-center-container {
  display: flex;
  justify-content: center;
}

.profile-user-col {
  max-width: 360px;
  min-width: 320px;
}

.profile-user-badge {
  max-width: 330px;
  min-width: 330px;
}

.disable-badge {
  opacity: 0.2;
}

.profile-table-col {
  max-width: 570px;
  min-width: 320px;
}

.profile-imagecontainer {
  max-width: 200px;
  max-height: 200px;
}
