$full-height: calc(100vh - 87px - 127px);

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Open+Sans:wght@400;600&display=swap');
@import 'bootstrap';
@import 'base';
@import 'objects';

// Pages
@import './pages/questionnaire';
@import './pages/success';
@import './pages/tips';

// Components
@import 'components';
@import 'components/slider';

@import 'utilities';
