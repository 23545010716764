.hero-row-reverse-mobile {
  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
  }
}

.fancy-text {
  background: linear-gradient(
    90deg,
    rgba(239, 101, 132, 0.98) 0%,
    rgba(61, 158, 103, 0.91) 76%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fancy-bg {
  background: linear-gradient(49deg, #3d9e67, #ef6584);
  background-size: 400% 400%;
  animation: gradientAnimation 30s ease infinite;
}

.darkgreen-bg {
  background-color: #3d9e67;
  background-size: 400% 400%;
}

.ReactModal__Overlay--after-open{
  z-index:99;
  .ReactModal__Content--after-open {
    width: 52%;
    margin: 100px auto;
    text-align: center;
    border: green 3px solid !important;
    box-shadow: 6px 12px 22px #333;
    border-radius: 12px;
    input {
        color:darkgreen;
        font-weight: bold;
        display: block;
        border: green 2px solid;
        font-size: 50px;
        width: 130px;
        border-radius: 33px;
        padding: 0 20px;
        margin: 20px auto;
      
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}

.background-color1 {
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(56,145,95,1) 0%, rgba(242,242,242,1) 72%);
  .jumbotron {
    background: none;
  }
}
.carousel{
  .btn {
    background-color: #B94421;
  }
  .card{
    background:transparent;
    border:none;
  }
}
