.fluid-img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

.success-img {
  width: 100%;
  max-width: 550px;
  margin-top: 3em;
  margin-bottom: 1em;
}
